<template>
  <div>
    <b-card>
      <!-- search & new -->
      <b-row>
        <b-col cols="2">
          <b-form-select
            placeholder="应用行业"
            v-model="query_industry"
            :options="industryOptions"
          />
        </b-col>
        <b-col cols="2">
          <b-form-select
            placeholder="合规要求"
            v-model="query_compliance"
            :options="complianceOptions"
          />
        </b-col>
        <b-col cols="3">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="query_keyword"
              placeholder="关键字"
              @keypress.enter="doSearch"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="cursor-pointer"
                @click="doSearch"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col cols="5" class="text-right">
          <b-button variant="primary" @click="newItem">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">新增</span>
          </b-button>
          <b-button variant="primary" @click="importExcel" class="ml-1">
            <feather-icon icon="ListIcon" class="mr-50" />
            <span class="align-middle">导入excel</span>
          </b-button>
          <div class="d-none">
            <b-form-file
              id="upload"
              ref="upload"
              accept=".xls, .xlsx"
              @change="fileChange"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- list -->
    <b-row v-if="queryData" class="match-height">
      <b-col lg="2" v-for="(item, idx) in queryData.list" :key="idx">
        <b-card
          no-body
          class="lab-item"
          :footer="`更新时间 ${item.time}`"
          footer-class="lab-footer"
          @click="currentItem = item"
        >
          <b-card-header>
            <b-media vertical-align="top">
              <template #aside>
                <b-badge variant="success" class="mr-1">
                  <b-icon-shield-lock /> <span>{{ item.industry }}</span>
                </b-badge>
              </template>
            </b-media>
            <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
          </b-card-header>

          <b-card-body>
            <h2 class="lib-title">{{ item.name }}</h2>
            <div>{{ item.description.substr(0, 30) }}...</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- pagination -->
    <b-row>
      <b-col cols="12" class="mt-1">
        <b-pagination
          v-if="queryData"
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!-- preview -->
    <b-modal
      id="lib-dict-preview"
      v-if="currentItem != null"
      :visible="true"
      centered
      size="lg"
      cancel-variant="primary"
      cancel-title="关闭"
      ok-variant="outline-danger"
      ok-title="编辑"
      @ok="editItem(currentItem.id)"
      @hide="currentItem = null"
    >
      <lib-dict-view :item="currentItem" />
    </b-modal>
  </div>
</template>


<script>
import {
  BMedia,
  BMediaAside,
  BIconShieldLock,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BFormFile,
  BModal,
} from "bootstrap-vue";

import * as XLSX from "xlsx/xlsx.mjs";

import LibDictView from "./LibDictView.vue";
import LibDictEdit from "./LibDictEdit.vue";

export default {
  name: "LibraryDict",

  components: {
    BMedia,
    BMediaAside,
    BIconShieldLock,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BFormFile,
    BModal,
    // EnterpriseEdit,
    LibDictView,
    LibDictEdit,
  },

  data() {
    return {
      queryData: null,

      // importData: null,

      // select options
      industryOptions: [
        { value: "", text: "应用行业" },
        "互联网",
        "车联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],
      complianceOptions: [
        { value: "", text: "合规要求" },
        "GDPR",
        "CCPA",
        "PIPL",
      ],

      //variant for dicts
      variantDicts: {
        GDPR: "primary",
        CCPA: "secondary",
        PIPL: "success",
      },

      // query
      query_industry: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      listTableFields: [
        { key: "code", label: "编号" },
        { key: "name", label: "企业名称" },
        { key: "compliances", label: "合规要求" },
        { key: "contactName", label: "联系人" },
        { key: "contactPhone", label: "电话" },
        { key: "industry", label: "所在行业" },
        { key: "contactEmail", label: "邮箱" },
        { key: "attachments", label: "附件材料" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // view
      currentItem: null,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.list : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      this.$http
        .get("/api/lib-dict", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.queryData = res.data;
        });
    },

    //
    editItem(id) {
      this.$router.push("/labrary/dict/" + id + "/edit");
    },

    //
    newItem() {
      //TODO:
    },

    //
    importExcel() {
      document.getElementById("upload").click();
    },

    // parse excel json
    parseJson(json){
      let sheet = json['Sheet1']
      this.queryData.list.splice(0)

      for (let i = 2; i < sheet.length; i++) {
        let row = sheet[i]
        if (row.length <11) return;

        let item = {}
        item.id = row[0]
        item.name = row[1]
        item.industry = row[2]
        item.enterpise = row[3]
        item.time = row[4]
        item.description = row[5]
        item.violation = row[6]
        item.basis = row[7]
        item.unit = row[8]
        item.type = row[9]
        item.punishment = row[10]
        console.log(item)

        this.queryData.list.push(item)

      }
    },

    // import excel
    fileChange(oEvent) {
      if (!oEvent.target.files.length) return

      var oFile = oEvent.target.files[0];

      var reader = new FileReader();
      var me = this

      reader.onload = function (e) {
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, { type: "array" });
        console.log(workbook);
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) result[sheetName] = roa;
        });
        // see the result, caution: it works after reader event is done.
        console.log(result);

        me.parseJson(result);

        oEvent.target.value = '';
      };
      reader.readAsArrayBuffer(oFile);
    },

    //
    deleteItem(data) {
      let name = data.item.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除企业“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/api/enterprise/${data.item.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>

<style lang="scss" >
.lib-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.lib-footer {
  font-size: 1rem !important;
  text-align: right !important;
}

.match-height > [class*="col"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  cursor: pointer;
}

.match-height > [class*="col"] > .card {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
</style>